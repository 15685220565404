import { setUserLanguage } from 'features/auth/slice';

export default function authEndpoints(builder) {
  return {
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth',
        method: 'POST',
        body: credentials,
      }),
    }),
    getAuthUser: builder.query({
      query: () => '/auth',
      providesTags: ['AuthUser'],
    }),
    impersonateUser: builder.mutation({
      query: (id) => ({
        url: `/auth/impersonate?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Session', 'Group', 'Course', 'Trainer'],
    }),
    adminResetPassword: builder.mutation({
      query: (id) => ({
        url: `/users/resend_invitation_email/${id}`,
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ token, ...body }) => ({
        url: `/auth/reset_password/${token}`,
        method: 'POST',
        body,
      }),
    }),
    updateUserPassword: builder.mutation({
      query: (user) => ({
        url: `/auth/change_password`,
        method: 'POST',
        body: user,
      }),
    }),
    validateRecaptcha: builder.query({
      query: (token) => ({
        url: '/auth/recaptcha',
        method: 'POST',
        body: { token },
      }),
    }),
    getResetToken: builder.mutation({
      query: ({ email, sendEmail, referrer }) => ({
        url: `/auth/getResetToken${referrer ? `?referrer=${referrer}` : ''}`,
        method: 'POST',
        body: { email: email.toLowerCase(), sendEmail },
      }),
    }),
    updateUserInfo: builder.mutation({
      query: (user) => ({
        url: `/auth/update_info/${user._id}`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['AuthUser'],
    }),
    updateUserLanguage: builder.mutation({
      async queryFn(user, { dispatch }, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ({
          url: `/auth/update_language/${user._id}`,
          method: 'POST',
          body: user,
        });

        if (response?.data?.user) {
          dispatch(setUserLanguage(response.data.user.language));
          return { data: response.data };
        } else {
          return { error: response?.error };
        }
      },
      invalidatesTags: ['Quiz'],
    }),
    updateLinkedInAuthorization: builder.mutation({
      query: ({
        name,
        urn,
        accessToken,
        accessTokenExpires,
        refreshToken,
        refreshTokenExpires,
      }) => ({
        url: `/linkedIn/authorization`,
        method: 'PUT',
        body: {
          name,
          urn,
          accessToken,
          accessTokenExpires,
          refreshToken,
          refreshTokenExpires,
        },
      }),
      invalidatesTags: ['AuthUser'],
    }),
    revokeLinkedInAuthorization: builder.mutation({
      query: () => ({
        url: `/linkedIn/authorization`,
        method: 'DELETE',
      }),
    }),
  };
}
