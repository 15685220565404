import 'react-app-polyfill/ie9';
import 'mdn-polyfills/Array.prototype.findIndex';
import 'promise-repeat-polyfill';
import 'polyfill-array-includes';
import 'array-flat-polyfill';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import { Backdrop } from '@mui/material';
import '@fontsource/poppins';

import store from './app/store';
import './index.css';
import App from './App';
import Loading from 'components/Loading';
import './app/i18n';

const isProduction = process.env.REACT_APP_ENV === 'production';

if (isProduction) {
  Sentry.init({
    dsn: 'https://dbf04528046f4a519fa74ba72e700a06@o922429.ingest.sentry.io/5869437',
    environment: 'production',
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: true,
      }),
    ],
    tracesSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    stickySession: true,
  });

  const tagManagerArgs = {
    gtmId: 'GTM-MH29RN4',
  };
  TagManager.initialize(tagManagerArgs);
}

const container = document.getElementById('root');
const root = createRoot(container);

const fallback = (
  <Backdrop open>
    <Loading />
  </Backdrop>
);

root.render(
  <Suspense fallback={<Loading />}>
    <Sentry.ErrorBoundary fallback={fallback}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  </Suspense>
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
