import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      light: '#5b5b5b',
      main: '#171616',
      dark: '#000000',
    },
    secondary: {
      light: '#d63333',
      main: '#CC0000',
      dark: '#a60321',
      mainYellow: '#ffeb49',
      lightYellow: '#fff080',
      greenCheck: '#0BA81A',
      lightBlue: '#80bdff',
      darkPurple: '#7e35cc',
      lightPurple: '#cfcef2',
      mediumGray: '#666666',
      lightGray: '#f4f4f4',
      gray: '#999999',
      pink: '#ffdddd',
      gainsboro: '#dcdcdc',
    },
    gray: {
      light: '#f4f4f4',
      main: '#999',
      dark: '#666',
    },
    purple: {
      main: '#7e35CC',
      dark: '#46207F',
      contrastText: '#fff',
    },
    info: {
      main: '#80bdff',
    },
    success: {
      main: '#0BA81A',
    },
    error: {
      main: '#CC0000',
    },
    button: {
      white: '#fff',
      whiteHover: '#999999',
      yellow: '#ffeb49',
      yellowHover: '#7F7524',
      green: '#4caf50',
      greenHover: '#357a38',
      orangeRed: '#f44336',
      red: '#CC0000',
      redHover: '#a60321',
      purple: '#7e35cc',
      purpleHover: '#46207f',
      gray: '#999999',
      grayHover: '#666666',
    },
    // New color palette
    // Bold1 used for grabbing attention, highlighting important info, and calls to action
    bold1: {
      light: '#d63333',
      main: '#B12945',
      dark: '#a60321',
    },
    // Pairs with Bold1 and Complimentary.  Used as alt accent color, should NOT be used for text or overlapping bold/other accent colors
    bold1Accent: {
      main: '#E48D30',
    },
    // Bold2 used for grabbing attention, highlighting important info, and calls to action
    bold2: {
      main: '#9DB8C8',
      contrastText: '#000000',
    },
    // Pairs with Bold2 and Complimentary.  Used as alt accent color, should NOT be used for text or overlapping bold/other accent colors
    bold2Accent: {
      main: '#A9BA9D',
      contrastText: '#000000',
    },
    // Complimentary used to contrast and compliment the bold color
    complimentary: {
      main: '#CCB363',
      contrastText: '#000000',
    },
    // Pairs with Bold2 and Complimentary, used as alt accent color, should NOT be used for text or overlapping bold/other accent colors
    complimentaryAccent: {
      main: '#CDDCE8',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: `"Poppins", "Open Sans", "Helvetica", "Arial", "Lucida", sans-serif`,
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    caption: {
      color: '#171616',
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 2,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08)',
          },
          '&:active': {
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.08)',
          },
        },
        contained: {
          boxShadow: '0 2px 4px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)',
          '&:hover': {
            boxShadow:
              '0 6px 12px rgba(0,0,0,0.15), 0 3px 6px rgba(0,0,0,0.10)',
          },
        },
        containedPrimary: {
          '&:hover': {
            color: '#fff',
            background: '#000',
          },
        },
        outlined: {
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
          },
        },
        text: {
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          transition: 'box-shadow 0.3s, transform 0.3s',
        },
      },
      variants: [
        {
          props: { variant: 'elevation', elevation: 0 },
          style: {
            boxShadow: 'none',
          },
        },
        {
          props: { variant: 'elevation', elevation: 1 },
          style: {
            boxShadow: '0 2px 4px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)',
          },
        },
        {
          props: { variant: 'elevation', elevation: 2 },
          style: {
            boxShadow: '0 4px 8px rgba(0,0,0,0.10), 0 2px 4px rgba(0,0,0,0.10)',
          },
        },
        {
          props: { variant: 'elevation', elevation: 3 },
          style: {
            boxShadow:
              '0 6px 12px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.12)',
          },
        },
        {
          props: { variant: 'elevation', elevation: 4 },
          style: {
            boxShadow:
              '0 8px 16px rgba(0,0,0,0.14), 0 4px 8px rgba(0,0,0,0.14)',
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          transition: 'box-shadow 0.3s, transform 0.3s',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow:
              '0 6px 12px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.12)',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiStepIcon: {
      completed: {
        color: '#0BA81A !important',
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 0,
          },
          '&$disabled': {
            backgroundColor: '#fff',
            opacity: 0.5,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&$expanded': {
            minHeight: 'auto',
          },
          '&$disabled': {
            opacity: 1,
          },
        },
      },
      content: {
        margin: 0,
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          marginBottom: 24,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {},
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
