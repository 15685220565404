export default function admin(builder) {
  return {
    mergeUsers: builder.mutation({
      query: ({
        preferredUsername,
        secondaryUsernames,
        activeCompanyUserIds = [],
        inactiveCompanyUserIds = [],
      }) => ({
        url: '/scripts/users/merge-accounts',
        method: 'POST',
        body: {
          preferredUsername,
          secondaryUsernames,
          activeCompanyUserIds,
          inactiveCompanyUserIds,
        },
      }),
      invalidatesTags: ['User'],
    }),
    getSessionKPIs: builder.query({
      query: (params) => ({
        url: '/reporting/sessionKpi',
        params,
      }),
      providesTags: ['SessionKpiReport'],
    }),
    getRenewalsTransactions: builder.query({
      query: () => ({
        url: '/reporting/renewal_transactions',
        responseHandler: (response) => response.text(),
      }),
    }),
    getTrainerAndSessionTotals: builder.query({
      query: () => `/reporting/trainer_and_session_totals`,
    }),
    getAdminData: builder.query({
      query: () => `/reporting/admin_data`,
    }),
    getTrainerListData: builder.query({
      query: () => `/reporting/trainer_list_data`,
    }),
    getRenewalExperimentResults: builder.query({
      query: ({ name, group }) => `/reporting/experiments/${name}/${group}`,
    }),
    getRenewalCharts: builder.query({
      query: (params) => ({
        url: '/reporting/renewals/charts',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getRenewalKpis: builder.query({
      query: (params) => ({
        url: '/reporting/renewals/kpis',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getRenewalReport: builder.query({
      query: (trainerId) => {
        let url = `/reporting/renewal_data`;
        if (trainerId) {
          url += `?trainerId=${trainerId}`;
        }
        return { url };
      },
      providesTags: [{ type: 'Report', id: 'renewal' }],
    }),
    getAllStudentData: builder.mutation({
      query: () => ({
        url: `/reporting/all_student_data`,
        method: 'GET',
      }),
    }),
    getOnDemandMonthlyAdminData: builder.query({
      query: (params) => ({
        url: `/reporting/on-demand-monthly-admin-data`,
        params,
        method: 'GET',
      }),
    }),
  };
}
